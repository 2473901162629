.section {
  background-color: #ffffff;
  margin-bottom: 20px;
  padding: 24px;
}

.flexSection {
  &:extend(.section);
  display: flex;
  flex-direction: column;
}

.flexFullSection {
  &:extend(.flexSection);
  flex: 1;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
}
.wrapItem {
  margin-top: 12px;
  margin-bottom: 12px;
}

.t4 {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 0;

  &WithBar {
    &:extend(.t4);
    &:before {
      content: ' ';
      display: inline-block;
      margin-right: 5px;
      width: 6px;
      height: 14px;
      background-color: #1890FF;
    }
  }
}

.tabs {
  :global(.ant-tabs-nav-wrap) {
    background-color: #ffffff;
    padding-left: 16px;
  }
}


@primary-color: #017BFF;@descriptions-item-padding-bottom: 10px;@descriptions-title-margin-bottom: 15px;