.breadcrumb {
  margin: -22px -24px 24px;
  padding: 24px 24px 10px;
  background-color: white;
}

.body {
  flex: 1;
  display: flex;
  padding: 0 25px 180px;
  flex-direction: column;
  background-color: white;
}

@primary-color: #017BFF;@descriptions-item-padding-bottom: 10px;@descriptions-title-margin-bottom: 15px;