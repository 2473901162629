.formSection {
  padding: 20px 24px;
  background-color: white;
}

.formMargin {
  &:extend(.formSection);
  margin-top: 20px;
}

.tableSection {
  padding: 0 24px;
  margin-top: 20px;
  background-color: white;
}

.deviceSection {
  margin: 20px 0;
  padding: 25px 24px 20px 29px;
  background-color: white;
}

@primary-color: #017BFF;@descriptions-item-padding-bottom: 10px;@descriptions-title-margin-bottom: 15px;