.body {
  display: flex;
  flex: 1;
  background-color: white;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 368px;

  &__title {
    font-size: 33px;
    font-weight: bold;
  }
  &__form {
    width: 100%;
    margin-top: 50px;
  }
  &__btn {
    width: 100%;
  }
}

@primary-color: #017BFF;@descriptions-item-padding-bottom: 10px;@descriptions-title-margin-bottom: 15px;