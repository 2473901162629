.titleBar {
  display: flex;
  align-items: center;

  &__vLine {
    width: 6px;
    height: 14px;
    background-color: #007BFF;
  }
  &__title {
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
  }
}

.body {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 50px 30px;
  background-color: white;
}

.itemTitle {
  color: rgba(0, 0, 0, 0.65);
  margin-top: 40px;
  margin-bottom: 10px;
}

.itemTag {
  width: 70px;
  height: 32px;
  margin-top: 20px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  text-align: center;
  padding: 6px 0;
}

.tagNor {
  &:extend(.itemTag);
  background-color: #e6e6e6;
}

.footer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
}

@primary-color: #017BFF;@descriptions-item-padding-bottom: 10px;@descriptions-title-margin-bottom: 15px;