// 使用驼峰命名法
// less 文件的命名以 .module.less 结尾

.container {

}
.content {
  //min-height: 100vh;
  margin: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.headerContainer {
  padding: 17px 0;
  text-align: center;
  display: block;
}

.header {
  background-color: #fff;
  height: 48px;
  line-height: 48px;
}

//.icon {
//  margin-right: 8px;
//}

.phone > p {
  line-height: 0;
  margin-top: 8px;
}
.slider {
  height: 100vh;
  overflow-y: scroll;
  box-shadow: 2px 0 6px 0 rgba(0, 21, 41, 0.12);
}

@primary-color: #017BFF;@descriptions-item-padding-bottom: 10px;@descriptions-title-margin-bottom: 15px;