body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1.ant-typography,
.ant-typography h1 {
  font-size: 24px;
}

h2.ant-typography,
.ant-typography h2 {
  font-size: 20px;
}

h3.ant-typography,
.ant-typography h3 {
  font-size: 16px;
}

h4.ant-typography,
.ant-typography h4 {
  font-size: 14px;
}

//@import '~antd/dist/antd.less';
.ant-table-thead > tr > th {
  font-weight: bold;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-weight: bold;
}

.ant-modal-title {
  font-weight: bold;
}

//.ant-modal-content > .ant-modal-footer {
//  border-top: none;
//}

.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.65);
}

@primary-color: #017BFF;@descriptions-item-padding-bottom: 10px;@descriptions-title-margin-bottom: 15px;